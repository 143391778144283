<!-- Modal to confirm re-running a previously ran batch -->
<template>
  <v-dialog justify="center" :value="value" persistent max-width="400">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="blue">mdi-arrow-up-bold-box</v-icon>
        </v-row>
        <v-row justify="center">
            <span class='pa-4'>
            Rerun  "{{item.name}}"?
            </span>
            <span class='pa-4' >
            Warning: This will overwrite all relevant data.
            </span>
        </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)" outlined>
            No
          </v-btn>
          <v-btn color="blue" height="40" class="white--text ml-2" 
            @click="resubmitItem(item.id)">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import BillingBatch from '@/axios/billing-batch';
import Statements from "@/axios/statements";
export default {
  name: 'ConfirmRerun',

  props: ["value", "selectedBatch", "selectedStatement"],

  data() {
      return {
      }
  },
  created() {
  },
  methods: {
    async resubmitItem(id) {
      this.$store.dispatch("setSnackbar", {
        status: "alert",
        text: `Resubmitting ${this.item.name}, Please Wait`,
      });

      try {
        await this.item.resubmit(id);
        this.$emit("update:value", false);
        this.$store.dispatch("setSnackbar", {
          status: "success",
          text: `Resubmitted ${this.item.name} Successfully`,
        });
      } catch (err) {
        this.$store.dispatch("setSnackbar", {
          status: "alert",
          text: `Failed to resubmit ${this.item.name}: ${err.message}`,
        });
      }
    },
  },
  computed: {
    item() {
      if (this.selectedBatch) {
        return {
          name: this.selectedBatch.name,
          id: this.selectedBatch.id,
          last_updated: this.selectedBatch.last_update_date,
          resubmit: async (id) => {
            return BillingBatch.resubmitBatch(id);
          },
        };
      } else {
        return {
          name: this.selectedStatement.name,
          id: this.selectedStatement.statement_id,
          last_updated: this.selectedStatement.updated_on,
          resubmit: async (id) => {
            return Statements.resubmitStatement(id);
          },
        };
      }
    },
  },
}
</script>